/* Import regular style toast */
@import '../public/assets/css/toastr.css';

html, body { 
    height: 100%; 
}

body { 
    margin: 0; 
    font-family: "Inter", sans-serif; 
}

.btn {
    font-family: 'Gelica', serif;
    font-weight: bold;
}

@font-face {
    font-family: 'Gelica';
    src: url('/assets/fonts/gelica.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gelica';
    src: url('/assets/fonts/gelica-italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Gelica';
    src: url('/assets/fonts/gelica-bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
  
@font-face {
    font-family: 'Inter';
    src: url('/assets/fonts/inter.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('/assets/fonts/inter-italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
  }

@font-face {
    font-family: 'Inter';
    src: url('/assets/fonts/inter-bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('/assets/fonts/inter-bold-italic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
  }

h1, h2, h3, h4, h5 {
    font-family: 'Gelica', serif;
    font-weight: bold;
}

.ellipsisAnimated::after {
    content: '...';
    animation: ellipsisAnimation 1.25s infinite;
}
  
@keyframes ellipsisAnimation {
    0% { content: ''; }
    33% { content: '.'; }
    66% { content: '..'; }
    100% { content: '...'; }
}

.breadcrumb-item {
    font-family: "Gelica", serif;
    font-size: 18px;
    font-weight: bold;
}

/* .breadcrumb-item a {
    color: grey;
} */

.breadcrumb-item.active {
    color: black;
}